








import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import IconTitleTextCard from '@/components/iconTitleTextCard.vue';
import { StorageServices } from '@/services/StorageServices'
import * as VM from '@/viewModel';
import * as OM from '@/model';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        IconTitleTextCard
    }
})
export default class MacroClassesSwitch extends Vue {

    classes: string[] = [];
    filterVm: OM.AssetFilterVm = new OM.AssetFilterVm();
    
    beforeRouteEnter(to, from, next){
        let filterVm = new OM.AssetFilterVm();
        filterVm.assetType = to.params.assetType;
        AssetClient.getAssetClasses(filterVm).then(x => {
            next((vc: MacroClassesSwitch) => {
                vc.classes = x;
                vc.filterVm = filterVm;
            });
        })
    }

    select(cl: string){
        this.$router.push('./' + this.filterVm.assetType + '/' + cl)
    }
}

